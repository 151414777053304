// @flow

import * as React from 'react'
import styled from '@emotion/styled'

import { Hero, text } from '@toggl/ui'

import IndexLayout from '../../layouts'
import LayoutTrack from '../../components/LayoutTrack'
import heroBg from 'images/uploads/homepage-hero-bg.jpg'
import videoSrc from 'images/uploads/bg-video.mp4'
import placeholder from 'images/uploads/integrations-placeholder.png'

const IntegrationsPage = () => {
  const HeroComponent = ({ children }) => {
    return (
      <Hero.Wrapper bgImage={heroBg} bgVideo={videoSrc}>
        {children}

        <Hero.ContentWrapper>
          <Left>
            <text.P1>Toggl Track button</text.P1>
            <text.H2>Now integrate with 100+ Tools</text.H2>
            <text.P1>
              Toggl Track Button is a browser extension that allows you to start
              the timer directly from online tools like Asana, Todoist, Trello
              and more. With features like the Pomodoro Timer, idle detection,
              and tracking reminders, it’s the ultimate tool to help you stay
              focused on work. Currently available on Chrome and Firefox.
            </text.P1>
          </Left>
          <Right>
            <text.P1>Download extension</text.P1>
            <a href="/">
              <ExtensionButton>
                <img src="" alt="Toggl Track Google Chrome" />
                <text.P1>Toggl Track Button</text.P1>
                <text.P3>for Google Chrome</text.P3>
              </ExtensionButton>
            </a>
            <a href="/">
              <ExtensionButton>
                <img src="" alt="Toggl Track Google Chrome" />
                <text.P1>Toggl Track Button</text.P1>
                <text.P3>for Google Chrome</text.P3>
              </ExtensionButton>
            </a>
            <text.P2>Prefer to use your mobile phone?</text.P2>
            <text.P2>
              Check out our{' '}
              <a href="" rel="noreferrer" target="_blank">
                iOS app
              </a>{' '}
              or{' '}
              <a href="" rel="noreferrer" target="_blank">
                Android app
              </a>
            </text.P2>
          </Right>
        </Hero.ContentWrapper>
      </Hero.Wrapper>
    )
  }

  return (
    <IndexLayout>
      <LayoutTrack Hero={HeroComponent}>
        <Search>
          <input type="text" placeholder="What are  you looking for?" />
        </Search>
        <Filters>
          <Pill>Filter tools:</Pill>
          <ul>
            <li>Web development</li>
            <li>Project management</li>
            <li>Writing & Communication</li>
            <li>CMS</li>
          </ul>
        </Filters>
        <Content>
          {integrationsData.map((integration, index) => (
            <Integration key={index}>
              <img src={integration.image} />
              <text.P2>{integration.title}</text.P2>
              <text.P3>{integration.description}</text.P3>
            </Integration>
          ))}
        </Content>
      </LayoutTrack>
    </IndexLayout>
  )
}

const integrationsData = [
  {
    image: placeholder,
    title: 'Asana',
    description: 'The easiest way for teams to track their work.',
  },
]

const Left = styled.div``
const Right = styled.div``
const ExtensionButton = styled.div``
const Search = styled.div``
const Filters = styled.div``
const Pill = styled.div``
const Content = styled.div``
const Integration = styled.div``

export default IntegrationsPage
